import { useCallback, useState } from "react"

export default function useShare() {
  const [state, setState] = useState({
    method:
      navigator.share != null ? ("share" as const) : ("clipboard" as const),
    isSharing: false,
    isShared: false,
  })
  const share = useCallback(
    async (options: { url: string; title: string; text: string }) => {
      setState((state) => ({ ...state, isSharing: true }))
      if (navigator.share != null) {
        await navigator.share({
          title: options.title,
          text: options.text,
          url: options.url,
        })
        setState((state) => ({
          ...state,
          isShared: true,
          isSharing: false,
        }))
        return { method: "share" as const }
      }
      await navigator.clipboard.writeText(`${options.text} ${options.url}`)
      setState((state) => ({
        ...state,
        isShared: true,
        isSharing: false,
      }))
      return { method: "clipboard" as const }
    },
    [setState],
  )

  return {
    share,
    setShareState: setState,
    ...state,
  }
}
